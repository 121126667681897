var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    overflowY: 'hidden',
    backgroundSize: '57% 100%',
    backgroundPosition: 'right center',
  }),attrs:{"fluid":""}})}
var staticRenderFns = []

export { render, staticRenderFns }