<template>
  <v-container
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
      overflowY: 'hidden',
      backgroundSize: '57% 100%',
      backgroundPosition: 'right center',
    }"
    class="background-container"
  >
    <!-- <v-row>
      <v-card
        class="d-flex align-center justify-center"
        :style="{
          borderRadius: '0px 25px 25px 0px',
          width: '45%',
          height: 'auto',
          position: 'relative',
        }"
      >
        <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-400px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#2D4490',
          }"
          title="Télécharger"
        >
          <v-icon center size="36" color="#FFFFFF">mdi-download</v-icon>
        </v-btn>
        <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-430px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#FFFFFF',
          }"
          title="Imprimer"
          @click="printCard"
        >
          <v-icon center size="36">mdi-printer</v-icon>
        </v-btn>
        <v-btn
          class="text-none"
          :style="{
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '400',
            'line-height': '20px',
            'letter-spacing': '0em',
            position: absolute,
            bottom: '-470px',
            left: '300px',
            display: flex,
            borderRadius: '50px',
            width: '60px',
            height: '60px',
            backgroundColor: '#FFFFFF',
          }"
          title="Partager"
        >
          <v-icon center size="36">mdi-share-variant-outline</v-icon>
        </v-btn>
        <v-card
          class="print-card"
          :style="{
            borderRadius: '8px',
            width: '500px',
            height: '666.08px',
            marginTop: '-200px',
            position: 'relative',
          }"
        >
          <v-card
            :style="{
              borderRadius: '10px',
              width: '450px',
              height: '600px',
              borderColor: color3,
              border: '3px solid',
              position: 'center',
              margin: '0 auto',
            }"
          >
            <v-sheet>
              <v-img
                :src="icon"
                :width="iconWidth"
                :style="{
                  flex: '0 0 auto ',
                  margin: '0 auto ',
                }"
                class="mx-auto print-visible"
              ></v-img>
              <div
                class="
                  text-center
                  mb-1
                  mt-6
                  justify-center
                  pl-8
                  pr-8
                  print-text
                "
                style="margin-left: 12px; margin-right: 12px"
              >
                <p
                  :style="{
                    styleTitle,
                    fontFamily: 'Affogato',
                    'font-size': '26px',
                    'line-height': '30px',
                    fontWeight: 700,
                    color: '#2D4490',
                  }"
                >
                  Scannez le QR code pour faire la photo d’identité officielle
                  de votre permis de conduire
                </p>
              </div>
            </v-sheet>
            <br />
            <v-sheet
              class="
                d-flex
                align-center
                justify-center
                flex-wrap
                text-center
                mx-auto
                px-4
                rounded-xl
                print-sheet
              "
              :style="{
                top: '1677px',
                left: '790px',
                border: '3px solid',
                borderColor: '#37E3AE',
                background: '#FFFFFF',
                margin: '0 auto',
              }"
              :elevation="1"
              :height="200"
              :width="200"
            >
            </v-sheet>
            <br />
            <div
              style="
                display: flex;
                align-items: center;
                position: absolute;
                left: 150px;
                bottom: 10px;
              "
            >
              <p
                :style="{
                  textAlign: 'center',
                  margin: '0',
                  color: '#000000',
                  'font-family': 'Georama',
                  fontWeight: 700,
                  'font-size': '10px',
                }"
              >
                Made by Smartphone
              </p>
              <v-img
                :src="icon"
                :width="iconWidth"
                :style="{
                  flex: '0 0 auto',
                  maxWidth: '35.84px',
                  maxHeight: '25.72px',
                  margin: '0',
                  borderRadius: '10px',
                }"
                class="mx-auto"
              ></v-img>
            </div>
          </v-card>
        </v-card>
        <v-sheet>
          <div
            style="
              position: absolute;
              bottom: 10px;
              left: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
            class="pl-3"
          >
            <p
              :style="{
                textAlign: 'center',
                margin: '0',
                color: '#000000',
                fontWeight: 700,
              }"
            >
              Made by Smartphone
            </p>
            <v-img
              :src="icon"
              :width="iconWidth"
              :style="{
                flex: '0 0 auto',
                maxWidth: '165.37px',
                maxHeight: '41.07px',
                margin: '5px 0 0 10px',
                borderRadius: '10px',
              }"
              class="mx-auto mb-1"
            ></v-img>
          </div>
        </v-sheet>
      </v-card>
    </v-row> -->
  </v-container>
</template>
<script>
// import { requestService } from '@/services/request.service'
// import { localStorageService } from '@/services/localStorage.service'
// import { saveAs } from 'file-saver'

export default {
  name: 'Authorization',
  // components: {},
  // data() {
  //   return {
  //     qrCode: null,
  //     imageSrc: null,
  //     styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
  //     buttonValidColor: localStorageService.getStyle(
  //       location.hostname,
  //       'buttonValidColor',
  //     ),
  //     buttonTextValidColor: localStorageService.getStyle(
  //       location.hostname,
  //       'buttonTextValidColor',
  //     ),
  //     buttonNeutralColor: localStorageService.getStyle(
  //       location.hostname,
  //       'buttonNeutralColor',
  //     ),
  //     buttonTextNeutralColor: localStorageService.getStyle(
  //       location.hostname,
  //       'buttonTextNeutralColor',
  //     ),
  //     icon: localStorageService.getStyle(location.hostname, 'icon'),
  //     iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),

  //     color1: localStorageService.getStyle(location.hostname, 'color1'),
  //     color2: localStorageService.getStyle(location.hostname, 'color2'),
  //     color3: localStorageService.getStyle(location.hostname, 'color3'),

  //     errors: [],
  //   }
  // },
  // methods: {
  //   downloadFile() {
  //     const byteCharacters = atob(this.qrCode)
  //     const byteNumbers = new Array(byteCharacters.length)
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i)
  //     }
  //     const byteArray = new Uint8Array(byteNumbers)
  //     const blob = new Blob([byteArray], { type: 'application/octet-stream' })
  //     saveAs(blob, 'smartphone-id_qrCode.jpeg')
  //   },
  //   printCard() {
  //     let printContents = document.querySelector('.print-card').innerHTML
  //     let originalContents = document.body.innerHTML
  //     document.body.innerHTML = printContents
  //     window.print()
  //     document.body.innerHTML = originalContents
  //   },
  // },
  // mounted() {
  //   const uuid = this.$route.params.uuid
  //   if (
  //     uuid !== undefined &&
  //     uuid !== null &&
  //     !localStorageService.getValue('logo')
  //   ) {
  //     requestService.get('/partner/uuid/' + uuid).then(response => {
  //       if (response.data.success) {
  //         this.company = response.data.company
  //         this.partnerPlan = response.data.partnerPlan
  //         this.partnerAdmin = response.data.partnerAdmin
  //         if (response.data.logo !== null) this.icon = response.data.logo
  //         localStorageService.setObject(
  //           'logo',
  //           response.data.logo !== null ? response.data.logo : undefined,
  //         )
  //         localStorageService.setObject(
  //           'primaryColor',
  //           response.data.primaryColor !== null
  //             ? response.data.primaryColor
  //             : undefined,
  //         )
  //         localStorageService.setObject(
  //           'secondaryColor',
  //           response.data.secondaryColor !== null
  //             ? response.data.secondaryColor
  //             : undefined,
  //         )
  //         localStorageService.setObject(
  //           'color1',
  //           response.data.color1 !== null ? response.data.color1 : undefined,
  //         )
  //         localStorageService.setObject(
  //           'color2',
  //           response.data.color2 !== null ? response.data.color2 : undefined,
  //         )
  //         localStorageService.setObject(
  //           'color3',
  //           response.data.color3 !== null ? response.data.color3 : undefined,
  //         )
  //         localStorageService.setObject(
  //           'police1',
  //           response.data.police1 !== null ? response.data.police1 : undefined,
  //         )
  //         localStorageService.setObject(
  //           'police2',
  //           response.data.police2 !== null ? response.data.police2 : undefined,
  //         )
  //         localStorageService.setObject(
  //           'police3',
  //           response.data.police3 !== null ? response.data.police3 : undefined,
  //         )
  //       }
  //     })
  //   }
  // },
}
</script>
<style scoped>
.v-card {
  border-radius: 0px 25px 25px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.background-container {
  /*background-size: cover;*/
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
}
@media print {
  .print-text {
    text-align: center;
    margin: auto;
    width: fit-content;
  }
  .print-visible {
    display: block !important;
    margin-bottom: 10% !important;
  }
  .print-sheet {
    margin-top: 5% !important; /* Rendre l'élément visible lors de l'impression */
    border-radius: 10%;
  }
}
</style>
